<template>
  <div id="component-tower-details-azimuth" class="w-100 bg-light">
    <iframe
      src="https://static.inference.asia/cesium/index.html"
      frameborder="0"
      class="d-block w-100 h-100"
      @load="$parent.isLoading = false"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "towerDetailsInteractiveTour",
  props: ["tower"],
  mounted() {
    this.$parent.isLoading = true;
  },
};
</script>